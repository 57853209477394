var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "h-screen flex w-full bg-img" }, [
    _c(
      "div",
      {
        staticClass:
          "vx-col flex items-center justify-center flex-col sm:w-1/2 md:w-3/5 lg:w-3/4 xl:w-1/2 mx-auto text-center",
      },
      [
        _c("img", {
          staticClass: "mx-auto mb-4 max-w-full",
          attrs: {
            src: require("@/assets/images/pages/404.png"),
            alt: "graphic-404",
          },
        }),
        _c(
          "h2",
          { staticClass: "sm:mx-0 mx-4 mb-12 text-5xl d-theme-heading-color" },
          [_vm._v("Not Found or Not Authorised!")]
        ),
        _c("p", { staticClass: "sm:mx-0 mx-4 mb-4 sm:mb-16" }, [
          _vm._v(
            "Either the page you are trying to access does not exist, or you may be trying to access a page that you are currently not authorised to view. Please try selecting an option from the menus."
          ),
        ]),
        _c("vs-button", { attrs: { size: "large", to: "/" } }, [
          _vm._v("Back to Home"),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }